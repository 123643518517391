.display {
  z-index: 9999;
  display: block;
  position: fixed;
  padding-top: 0.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-background-show);
}

.show-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 5% 90% 5%;
  gap: 0.5rem;
  width: 90%;
  padding-left: 5rem;
}

.image-holder {
  display: flex;
  justify-content: center;
}

.image-holder img {
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 2px solid var(--color-background);
}

.slide-vertical {
  flex: 0.575;
}

.slide-horizontal {
  flex: 1.5;
}

.left-button {
  cursor: pointer;
  color: white;
  font-size: 45px;
  font: bolder;
}

.right-button {
  cursor: pointer;
  color: white;
  font-size: 45px;
  font: bolder;
}

.close-button {
  position: absolute;
  cursor: pointer;
  color: white;
  font-size: 30px;
  margin-top: 2rem;
  padding: 1rem;
  margin-left: 90%;
  align-content: center;
  justify-self: center;
  align-self: center;
}


.all-images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  padding: 1.5rem;
  background: var(--color-background-show-variant);
}

.all-images img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  margin: 0.5rem;
  border: none;
}

/* ========= DEVICE SIZE ======= */
@media screen and (max-width: 1024px){

}

@media screen and (max-width: 600px) {
  .show-wrapper {
    padding-left: 0.5rem;
  }

  .left-button {
    font-size: 35px;
  }

  .right-button {
    font-size: 35px;
  }

  .close-button {
    margin-top: 0.5rem;
    margin-left: 85%;
  }
}
