.cover img {
  display: block;
  width: 100%;
  object-fit: cover;
  min-height: 100vh;
}

.container-title {
  display: grid;
  position: absolute;
  top: min(15%);
  left: 50%;
  transform: translate(-50%, 0);
  grid-template-columns: 1000px;
  grid-auto-rows: 80px;
  justify-content: center;
  justify-items: center;
}


.container-title h1 {
  background: var(--color-title);
  background-clip: text;
  color: transparent;
  -webkit-text-stroke: 3px var(--color-navigation-tx);
}

.container-title a {
  font-size: 20px;
  padding-top: 5rem;
  color: var(--color-navigation-tx);
}

.container-title a:hover {
  font-weight: bolder;
  color: var(--color-navigation-tx-variant);
}

.container-title hr {
  margin-top: 3rem;
  margin-bottom: 1rem;
  border: 2px solid var(--color-navigation-tx);
  width: 50%;
  transform: translate(50%, 0);
}

/* ========= DEVICE SIZE ======= */
@media screen and (max-width: 1024px){
  .container-title {
    top: 20%;
    grid-template-columns: 800px;
    grid-auto-rows: 70px;
  }
  .container-title h1 {
    font-size: 3.5rem;
    -webkit-text-stroke: 2px var(--color-navigation-tx);
  }
}

@media screen and (max-width: 800px){
  .cover img {
    object-position: 40% 0;
  }

  .container-title {
    grid-template-columns: 600px;
    grid-auto-rows: 50px;
  }
  .container-title h1 {
    font-size: 2.8rem;
    -webkit-text-stroke: 1px var(--color-navigation-tx);
  }
}

@media screen and (max-width: 600px) {
  .container-title {
    grid-template-columns: 500px;
    grid-auto-rows: 40px;
  }
  .container-title h1 {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 450px) {
  .container-title {
    grid-template-columns: 400px;
  }

  .container-title h1 {
    color: var(--color-title);
     font-size: 1.7rem;
    -webkit-text-stroke: 0.5px var(--color-navigation-tx);
  }
}