@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-primary: rgba(191, 196, 199, 0.5);
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-navigation-bg: rgba(255, 255, 255, 0.447);
  --color-navigation-tx:rgb(38, 67, 92);
  --color-navigation-tx-variant:rgb(14, 24, 32);
  --color-title: rgba(255, 255, 255, 0.4);
  --color-background: rgb(249, 249, 249);
  --color-background-show: rgb(27, 27, 27);
  --color-background-show-variant: rgb(62, 62, 62);

  --container-width-lg: 90%;
  --container-width-md: 95%;
  --container-width-sm: 98%;
  --shadow: -1px 15px 19px 5px rgba(0,0,0,0.22);
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.7;
  /* prevent overflow problems */
  overflow-x: hidden;
  background-color: var(--color-background);
}

html {
  scroll-behavior: smooth;
}

button {
  color: var(--color-navigation-tx);
}

button:hover {
  background-color: white;
}

::-webkit-scrollbar {
  display: none;
}

/* ========= GENERAL STYLES ======= */
.background {
  background-color: var(--color-primary);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.8rem;
  padding-top: 5rem;
}

.title h2 {
  text-transform: uppercase;
  color: var(--color-navigation-tx);
}

.title hr {
  margin-bottom: 1rem;
  border: 2px solid var(--color-navigation-tx);
  width: 50%;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 4rem;
  font-weight: bolder;
}

h2 {
  font-size: 2.4rem;
  font-weight: bolder;
}

p {
  font-size: 18px;
}

section {
  margin-top: 6rem;
  min-height: 100vh;
}

/* ========= MEDIUM DEVICES ========= */
@media screen and (max-width: 1024px){
  .container {
    width: var(--container-width-md);
  }

  body {
    line-height: 1.5;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 14px;
  }
  
  section {
    margin-top: 6rem;
  }

  .title {
    padding-top: 8rem;
  }
}

/* ========= SMALL DEVICES ========= */
@media screen and (max-width: 600px){
  .container {
    width: var(--container-width-sm);
  }

  body {
    line-height: 1.3;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }
  
  section {
    margin-top: 3rem;
  }
}