.recent-wrapper {
  display: block;
  padding-top: 1rem;
  padding-left: 5rem;
  width: 95%;
  min-height: 100vh;
  overflow: hidden;
}

.styiling {
  position: absolute;
  place-self: start;
  background-color: var(--color-primary);
  width: 35%;
  height: 60%;
}

.recent-slide {
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  width: 100%;
}

.image-container {
  display: flex;
  width: 97%;
}

.image-container img {
  width: 100%;
  height: auto;
  box-shadow: var(--shadow);
  border: 1px solid var(--color-navigation-bg);
}

.vertical {
  flex: 0.58;
  padding-left: 4rem;
}

.horizontal {
  flex: 1.5;
}

.slide {
  opacity: 0;
  transform: translateX(50%);
  transition: all 0.6s ease-in-out;
}

.current {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.6s ease-in-out; 
}

.details {
  position: absolute;
  right: 7rem;
  padding: 2rem;
  border-radius: 25px;
  background: var(--color-navigation-bg);
  border: 1px solid var(--color-navigation-bg);
  animation: slide-up 1s ease 0.6s;
  animation-fill-mode: forwards;
  visibility: hidden;
  box-shadow: var(--shadow);
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 95%
  }
  100% {
    visibility: visible;
    top: 70%
  } 
}

/* ========= DEVICE SIZE ======= */
@media screen and (max-width: 1024px){
  .recent-wrapper {
    padding-top: 0.5rem;
    padding-left: 3rem;
  }
  .recent-slide {
    padding-left: 1.5rem;
  }
  .details {
    right: 3rem;
  }

  .vertical {
    padding-left: 2rem;
  }

  .styiling {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 90%
    }
    100% {
      visibility: visible;
      top: 60%
    }
  }
}

@media screen and (max-width: 600px) {
  .recent-wrapper {
    width: 100%;
    padding-left: 0rem;
    padding-top: 0rem;
  }
  .image-container {
    justify-content: center;
  }
  .recent-slide {
    padding-left: 0.8rem;
  }

  .vertical {
    padding-left: 0.3rem;
    flex: 0.55;
  }

  .horizontal {
    padding-left: 0rem;
  }

  .details {
    left: 2rem;
  }

  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 140%
    }
    100% {
      visibility: visible;
      top: 110%
    }
  }
}