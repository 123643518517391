.about-wrapper {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-areas:
    'desc pic'
    'desc order'
    'desc social';
}

.bg-styling {
  grid-area: pic;
  background-color: var(--color-primary);
  width: 50%;
  height: 80%;
}

.profile {
  grid-area: pic;
  display: flex;
}

.profile img {
  object-fit: cover;
  width: 85%;
  margin: 2rem;
  border: 1px solid var(--color-navigation-bg);
  box-shadow: var(--shadow);
}

.about-desc {
  grid-area: desc;
  width: 90%;
  background-color: white;
  padding: 2rem;
  box-shadow: var(--shadow);
}

.order-links-card {
  grid-area: order;
  height: 11rem;
  margin-right: 2rem;
  transform-style: preserve-3d;
  transition: 1s ease-in-out;
}

.order-links-card:hover .front {
  transform: rotateX(-180deg);
}

.order-links-card:hover .back {
  transform: rotateX(0deg);
}

.front {
  position: absolute;
  border-radius: 1.1rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url(../../assets/images/card-front.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transition: 1s ease-in-out;
}

.front p {
  padding-top: 1.5rem;
  color: var(--color-navigation-tx);
  font-size: 1.8rem;
  font-weight: 500;
  text-shadow: 2px 2px var(--shadow);
}

.back {
  background-color: var(--color-navigation-tx);
  border-radius: 1.1rem;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateX(180deg);
  transition: 1s ease-in-out;
}

.back p {
  padding-top: 1.5rem;
  padding-bottom: 0.8rem;
  color: var(--color-background)
}

.back a {
  cursor: pointer;
  color: var(--color-background)
}


.social {
  grid-area: social;
  display: flex;
  padding-top: 1rem;
  margin-right: 2rem;
  justify-content: flex-end;
}

.social a {
  margin-left: 2rem;
  color: var(--color-navigation-tx);
  font-size: 40px;
  transition: all 0.3s ease-in-out;
}

.social a:hover {
  transform: translateY(-0.3rem);
}

.instagram {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 2rem;
  min-height: 24rem;
  justify-content: center;
}

.instagram img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.instagram a {
  position: absolute;
  margin-top: 1rem;
  color: var(--color-navigation-tx);
  font-size: 65px;
}

.instagram_btn {
  margin-top: min(6rem);
  display: grid;
  place-items: center;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.style_btn {
  background-color: white;
  position: absolute;
}

.style_btn:hover {
  box-shadow: var(--shadow);
  transform: translateY(-1rem);
}

.instagram_btn p {
  font-family: monospace, monospace;
  font-size: 1rem;
  font-weight: 600;
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  animation: spinText 20s linear infinite;
}

.instagram_btn p span {
  position: absolute;
  transform-origin: 0.3rem 5.9rem;
}

@keyframes spinText {
  to {
    transform: rotate(360deg);
  }
}

/* ========= DEVICE SIZE ======= */
@media screen and (max-width: 1024px){
  .order-links-card {
    height: 8rem;
  }
  .front p {
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  .instagram {
    margin-top: 2rem;
    margin-bottom: 0rem;
    min-height: 18rem;
  }
  
  .instagram_btn {
    transform: translate(0, -25%);
  }
}

@media screen and (max-width: 600px) {
  .about-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
    'desc'
    'pic'
    'order'
    'social';
  }

  .about-desc {
    justify-self: center;
    width: 98%;
  }

  .bg-styling {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .profile img {
    margin-left: 3rem;
    width: 80%;
  }

  .order-links-card {
    margin: 0.5rem;
  }

  .social {
    padding-top: 1rem;
    margin-right: 0rem;
    justify-content: center;
  }
}
