.container-nav {
  z-index: 9000;
  display: flex;
  position: fixed;
  width: var(--container-width-lg);
  max-width: 100%;
}

/*====== LOGO SETUP =====*/
.navbar-logo {
  background-color: var(--color-background-show);
  justify-content: center;
  place-items: center;
  margin-top: 0.5rem;
  width: 9rem;
  height: auto;
  box-shadow: var(--shadow);
}

.navbar-logo img {
  padding: 0.2rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  max-width: 90%;
  height: auto;
}

/*=========== NAVBAR  ===========*/
.navbar {
  padding: 0.3rem;
  border-radius: 25px;
  background-color: var(--color-navigation-bg);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid var(--color-navigation-bg);
  z-index: 2;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  box-shadow: var(--shadow);
}

.navbar-links {
  padding: 1rem 2rem;
  margin: 0rem 2rem;
  display: flex; 
  justify-content: center;
}

.navbar-links a {
  display: block;
  width: 5rem;
  margin: 0 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: var(--color-navigation-tx);
}

.navbar-links a:hover {
  color: var(--color-navigation-tx-variant);
}

.navbar-links a.active {
  color: var(--color-navigation-tx-variant);
}

.navbar-links a.active .underline {
  position: absolute;
  display: block;
  height: 0.2rem;
  width: 5rem;
	background-color: var(--color-navigation-tx-variant);
  transform: scaleX(0.7);
  transition: all 0.8s;
}


/* ========= BURGER MENU AND DEVICE SIZE ======= */
.burger {
  display: none;
}

.mobile-button {
  display: none;
}

.mobile-button div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
}

@media screen and (max-width: 1024px){
  .container-nav {
    width: var(--container-width-md);
  }

  .navbar {
    max-width: 73%;
  }

  .navbar-links a {
    margin: 0 0.5rem;
  }
}

@media screen and (max-width: 800px){
  .navbar-links a {
    margin: 0rem;
  }
}

@media screen and (max-width: 600px) {
  .container-nav {
    width: var(--container-width-sm);
  }

  .navbar-logo {
    width: 7rem;
  }

  .navbar {
    display: none;
  }

  .burger {
    display: block;
  }

  .mobile-button {
    display: block;
    cursor: pointer;
    z-index: 9001;
    padding: 1rem;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background-color: var(--color-navigation-bg);
    border: 1px solid var(--color-navigation-bg);
    box-shadow: var(--shadow);
  }

  .mobile-menu.showMenu {
    transform: translateX(0%);
  }

  .mobile-menu {
    z-index: 1000;
    margin: 0;
    position: fixed;
    inset: 0 0 0 50%;
    
    display: flex;
    flex-direction: column;
    
    background-color: var(--color-background);

    height: 100vh;
    transform: translateX(100%);
    transition: transform 350ms ease-out
  }

  .mobile-menu ul {
    padding: 5rem 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .mobile-menu li {
    margin: 2rem 2rem;
  }

  .mobile-menu a {
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: var(--color-navigation-tx);
  }

  .mobile-menu a:hover {
    color: var(--color-navigation-tx-variant);
  }

  /*====== TRANSFORM BURGER BUTTON TO X =====*/
  .line1.toggle {
    transform: rotate(-45deg) translate(-5px,6px);
  }

  .line2.toggle {
    opacity: 0;
  }

  .line3.toggle {
    transform: rotate(45deg) translate(-5px,-6px);
  }
}
